import { Controller } from "@hotwired/stimulus"
import TomSelect from "tom-select";

// Connects to data-controller="invoice"
export default class extends Controller {
  static targets = ["invoiceTypehiddenItem"]

  billing_addresses = new TomSelect("#invoice_billing_address_id",{
    valueField: 'id',
    labelField: 'full_address',
    optgroupField: 'address_type',
    searchField: ['company_name', 'name', 'address_line_1', 'address_line_2', 'city', 'state_province', 'postal_code', 'country'],
    // fetch remote data
    optgroups: [
      {value: 'warehouse', address_type: 'Warehouses'},
      {value: 'retailer', address_type: 'Retailers'},
      {value: 'customer', address_type: 'Customers'},
      {value: 'accounts_payable', address_type: 'Accounts Payables'},
    ],
    load: function(query, callback) {
      let url = document.querySelector("#invoice_billing_address_id").dataset.dataUrl
      if(query){
        url = url + '?limit=20&query=' + encodeURIComponent(query);
      }
      fetch(url)
      .then(response => response.json())
      .then(json => {
        callback(json);
      }).catch(()=>{
        callback();
      });
    },
    create: function(input, _callback) {
      const newLink = document.querySelector("#add_new_billing_address")
      if(newLink){
        newLink.click()
      }
    },
    render: {
      optgroup_header: function(data, escape) {
        return '<h3 class="text-primary optgroup-header sticky top-0">' + escape(data.address_type) +'</h3>';
      },
      option: function(data, escape) {
        return JSON.parse(data.full_address);
      },
      item: function(data, escape) {
        return JSON.parse(data.full_address);
      }
    }
  });


  connect() {
  }

  updateTotals(event){
    const template = event.target.closest('.template')
    const eventType = event.target.dataset.type
    const item = template.querySelector('[data-type="qty"]')
    const qty = parseFloat(Number(template.querySelector('[data-type="qty"]').dataset.value))
    let price = template.querySelector('[data-type="price"]').value
    let total = template.querySelector('[data-type="total"]').value
    if(eventType == 'price'){
      template.querySelector('[data-type="total"]').value = (qty * price).toFixed(2)
    }
    if(eventType == 'total'){
      template.querySelector('[data-type="price"]').value = (total / qty)
    }
  }

  validateAddress(event){
    const value = event.target.value
    const addressTag = event.target.dataset.addressTag
    const object = document.querySelector(addressTag)
    if(object){
      let url = new URL(object.src)
      let params = url.searchParams
      params.set('address_id', value)
      url.search = params.toString()
      object.src = url.toString()
    }
  }

  requestShippingCost(event){
    const checked = event.target.checked
    const template = event.target.closest('.template')
    const name = template.querySelector('[data-type="name"]')
    const description = template.querySelector('[data-type="description"]')
    const unitCost = template.querySelector('[data-type="price"]')
    const TotalCost = template.querySelector('[data-type="total"]')
    if(checked){
      name.disabled = true
      description.disabled = true
      unitCost.disabled = true
      TotalCost.disabled = true
    }else{
      name.disabled = false
      description.disabled = false
      unitCost.disabled = false
      TotalCost.disabled = false
    }
  }

  changeInvoiceType(event){
    const id = event.target.value
    if(id == "Square"){
      this.invoiceTypehiddenItemTargets.forEach(ele => {
        ele.removeAttribute("hidden")
      });
    }else{
      this.invoiceTypehiddenItemTargets.forEach(ele => {
        ele.setAttribute("hidden", true)
      });
    }
  }

}
