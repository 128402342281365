import { Controller } from "@hotwired/stimulus"
import TomSelect from "tom-select";

// Connects to data-controller="shipping-rates"
class ShippingRates extends Controller {
  static targets = ["componentField"]

  shipping_service = new TomSelect("#shipment_service_type",{
    valueField: 'service_type',
    labelField: 'display',
    optgroupField: 'class',
    optgroups: [
      {value: 'usps', label: 'USPS'},
      {value: 'ups', label: 'UPS'}
    ],
    // fetch remote data
    load: function(query, callback) {
      document.querySelector("#loading-spinner").hidden = false
      const self = this;
      const value = document.querySelector("#shipment_service_type").dataset.default
      let box
      let shipTo = document.querySelector("#shipment_shipping_address_id").value
      let shipFrom = document.querySelector("#shipment_warehouse_id").value
      if(document.querySelector("#shipment_box_id")){
        box = document.querySelector("#shipment_box_id").value
      }
      let weight = document.querySelector("#shipment_weight").value
      let width = document.querySelector("#shipment_box_width").value
      let depth = document.querySelector("#shipment_box_depth").value
      let height = document.querySelector("#shipment_box_height").value
      // let customBox = document.querySelector("#shipment_custom_box").checked
      let url = new URL(document.querySelector("#shipment_service_type").dataset.url, window.location.origin);


      if (width && depth && height && weight){
        document.querySelectorAll(".component_template").forEach(element => {
          if(element.querySelector("[data-shipping-rates-target='componentField']")){
            if(element.style.display == 'none'){
            }else{
              for(let i = 0; i < element.querySelector("[data-type='quantity']").value; i++){
                url.searchParams.append('parts[]', element.querySelector("[data-shipping-rates-target='componentField']").dataset.value)
              }
            }
          }
        });
        url.searchParams.append('ship_to', shipTo)
        url.searchParams.append('ship_from', shipFrom)
        if(weight){ url.searchParams.append('weight', weight) }
        // if (customBox){
        if(width){ url.searchParams.append('width', width) }
        if(depth){ url.searchParams.append('depth', depth) }
        if(height){ url.searchParams.append('height', height) }
        // }else{
        //   if(box){ url.searchParams.append('box_id', box) }
        // }
      }
      fetch(url)
      .then(response => response.json())
      .then(json => {
        callback(json);
        self.setValue(value);
      }).catch(()=>{
        callback();
      });
    },
    onLoad: function(){
      document.querySelector("#loading-spinner").hidden = true
    },
    render: {
      option: function(data, escape) {
        return (data ? JSON.parse(data.display) : "");
      },
      item: function(data, escape) {
        return (data ? JSON.parse(data.display) : "");
      }
    }
  });

  setServiceValue(value){
    this.shipping_service.setValue(value)
  }

  connect() {
    this.getShippingRates()
    console.log("Connected Shipping Rates")
  }

  componentFieldTargetConnected(){
    this.getShippingRates()
  }

  getShippingRates(){
    setTimeout(() => {
      if(this.shipping_service.getValue()){
        document.querySelector("#shipment_service_type").dataset.default = this.shipping_service.getValue()
      }
      const value = document.querySelector("#shipment_service_type").dataset.default
      this.shipping_service.setValue(null)
      this.shipping_service.clearOptions();
      this.shipping_service.load()
    }, 300);
  }


}

export default ShippingRates;
