import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="search"
export default class extends Controller {
  static targets = ["file", "folder"]

  connect() {
  }

  reset(event){
    event.target.form.reset()
    const tomSelected = event.target.form.querySelectorAll(".tomselected")
    if (tomSelected.length > 0){
      tomSelected.forEach((tomselectedElement) => {
        tomselectedElement.tomselect.clear();
      });
    }
    this.element.requestSubmit()
  }

  search(event){
    let delay = 200
    if(event.params.wait){
      delay = event.params.wait
    }
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      this.element.requestSubmit()
    }, delay)
  }

  fileSearch(event){
    const searchValue = event.target.value.toLowerCase()
    this.fileTargets.forEach((file) => {
      if (file.textContent.toLowerCase().includes(searchValue)) {
        file.hidden = false
        file.classList.add('visible');
      }else{
        file.hidden = true
        file.classList.remove('visible');
      }
    })
    this.folderTargets.forEach((folder) => {
      const files = folder.querySelectorAll(`[data-search-target="file"].visible`)
      console.log(files.length)
      if(files.length > 0){
        folder.hidden = false
      }else{
        folder.hidden = true
      }
    })
  }

}

