import { Controller } from "@hotwired/stimulus"
import TomSelect from "tom-select";

// Connects to data-controller="addresses"
export default class extends Controller {
  static targets = ["addresses"];

  connect() {
  }


}
