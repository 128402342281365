import { Controller } from "@hotwired/stimulus"
import TomSelect from "tom-select";

// Connects to data-controller="tickets"
export default class extends Controller {
  static targets = ["newContact"];
  retailers = new TomSelect("#ticket_retailer_id",{
      valueField: 'id',
      labelField: 'name',
      searchField: ['name'],
      load: function(query, callback) {
        let url = document.querySelector("#ticket_retailer_id").dataset.dataUrl
        if(query){
          url = url + '?query=' + encodeURIComponent(query);
        }
        fetch(url)
        .then(response => response.json())
        .then(json => {
          callback(json);
        }).catch(()=>{
          callback();
        });
      },
      render: {
        optgroup_header: function(data, escape) {
          return '<h3 class="optgroup-header sticky top-0">' + escape(data.label) +'</h3>';
        }
      }
    });

  locations = new TomSelect("#ticket_retailer_location_id",{
    valueField: 'id',
    labelField: 'full_address',
    dataAttr: 'address_id',
    searchField: ['company_name', 'name', 'address_line_1', 'address_line_2', 'city', 'state_province', 'postal_code', 'country'],
    // fetch remote data
    load: function(query, callback) {
      let url = document.querySelector("#ticket_retailer_location_id").dataset.dataUrl
      url = url + "?retailer_id=" + document.querySelector("#ticket_retailer_id").value
      if(query){
        url = url + '&query=' + encodeURIComponent(query);
      }
      fetch(url)
      .then(response => response.json())
      .then(json => {
        callback(json);
      }).catch(()=>{
        callback();
      });
    },
    render: {
      option: function(data, escape) {
        if(data.full_address){
          return `<div data-address-id="${data.address_id}">${JSON.parse(data.full_address)}</div>`;
        }
      },
      item: function(data, escape) {
        if(data.full_address){
          return `<div data-address-id="${data.address_id}">${JSON.parse(data.full_address)}</div>`;
        }
      }
    }
  });

  contacts = new TomSelect('#ticket_contact_id',{
    valueField: 'id',
    labelField: 'name',
    searchField: ['name', 'email'],
    // fetch remote data
    load: function(query, callback) {
      let url = document.querySelector("#ticket_contact_id").dataset.dataUrl
      if(query){
        url = url + '?query=' + encodeURIComponent(query);
      }
      fetch(url)
      .then(response => response.json())
      .then(json => {
        callback(json);
      }).catch(()=>{
        callback();
      });
    },
    render: {
      option: function(data, escape) {
        return `<div>${data.name} [${data.email}]</div>`;
      },
      item: function(data, escape) {
        return `<div>${data.name} [${data.email}]</div>`;
      }
    }
  });

  connect() {
  }

  checkContact(event){
    const value = event.target.value
    const object = document.querySelector('#check_contact')
    if(object){
      let url = new URL(object.src)
      let params = url.searchParams
      params.set('contact_id', value)
      url.search = params.toString()
      object.src = url.toString()
    }
  }

  newContactTargetConnected(element){
    const id = element.dataset.id
    const name = element.dataset.name
    const email = element.dataset.email
    this.contacts.removeOption(id)
    this.contacts.addOption({id: id, name: name, email: email})
    this.contacts.setValue(id)
    element.remove()
  }

  fetchLocations(){
    this.locations.clearOptions();
    this.locations.load();
    this.locations.setValue(null)
  }
}
