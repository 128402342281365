import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="shipments"
export default class extends Controller {
  static targets = ["refreshPartOrders"]

  // shippingAddressInput = document.querySelector('#shipment_shipping_address_id')
  // shippingAddressText = document.querySelector("#ship_to_address")
  // shippingTable = document.querySelector("#shipment-table")
  // addShipmentItemBtn = document.querySelector("#add-shipment-item-btn")

  updateShippingInfo(addressId=null){
    if(addressId){
      if(document.querySelector('#shipment_shipping_address_id').value !== addressId){
        document.querySelector('#shipment_shipping_address_id').value = addressId
        this.updateShippingAddressText()
        this.hidePartOrdersBasedOnShippingAddress()
      }
    }else{
      const shipmentItems = Array.from(document.querySelector("#shipment-table").querySelectorAll('.shipment_shipment_items_quantity input'));
      const filteredItems = shipmentItems.filter(item => Number(item.value) > 0);
      if(filteredItems.length <= 0){
        document.querySelector('#shipment_shipping_address_id').value = null
        this.updateShippingAddressText()
        this.hidePartOrdersBasedOnShippingAddress()
      }
    }
  }

  updateShippingAddressText(){
    if(document.querySelector('#shipment_shipping_address_id').value){
      document.querySelector("#ship_to_address").innerHTML = document.querySelector(`#address_${document.querySelector('#shipment_shipping_address_id').value} .ship_to`).innerHTML
    }else{
      document.querySelector("#ship_to_address").innerHTML = "NO ADDRESS SELECTED"
    }
  }

  hidePartOrdersBasedOnShippingAddress() {
    const addressId = document.querySelector('#shipment_shipping_address_id').value;
    document.querySelectorAll('.shipping_address').forEach(element => {
      element.hidden = addressId && element.id !== `address_${addressId}`;
    });
  }

  refreshPartOrdersTargetConnected(){
    document.querySelector("#part_orders_shipments").reload()
  }

  matchingRow(element) {
    const found = document.querySelector("#shipment-table").querySelector(`input[value='${element.dataset.partOrderItemId}'].part_order_item`);
    const row = found ? found.closest('tr') : this.addShipmentItemAndReturnRow();
    this.makeVisible(row);
    return row;
  }

  addShipmentItemAndReturnRow() {
    document.querySelector("#add-shipment-item-btn").click();
    return document.querySelector("#shipment-table").rows[document.querySelector("#shipment-table").rows.length - 1];
  }

  addItem(event){
    let matchingRow = this.matchingRow(event.target)
    this.setValues(event.target, matchingRow, 1)
    this.updateShippingInfo(event.target.dataset.addressId)
    this.checkHiddenElements(event.target)
  }

  removeItem(event){
    const row = event.target.closest('tr')
    const element = document.querySelector("[data-part-order-item-id='" +  row.querySelector('input.part_order_item').value + "']")
    const quantity = Number(row.querySelector('input.quantity').value) * -1
    this.setValues(element, row, quantity)
    this.checkHiddenElements(element)
    this.updateShippingInfo()
  }

  addAllItems(event){
    this.updateShippingInfo(event.target.dataset.addressId)
    const table = event.target.closest('.card').querySelector('.part_order_items_table')
    for (var i = 1, element; element = table.rows[i]; i++) {
      const dataItem = element.querySelector('.part_order_data')
      if(dataItem && dataItem.dataset.quantity > 0){
        let matchingRow = this.matchingRow(dataItem)
        this.setValues(dataItem, matchingRow, dataItem.dataset.quantity);
        this.checkHiddenElements(dataItem)
      }
    };
  }

  setValues(element, row, quantity){
    const ShippingRatesController = this.application.getControllerForElementAndIdentifier(document.querySelector("#shipping-rates-controller"), 'shipping-rates')
    ShippingRatesController.setServiceValue(element.dataset.serviceType)
    this.assignQuotedShippingValue()
    document.querySelector('#shipment_default_service_type').value = element.dataset.serviceType
    row.querySelector("input.part_order_item").value = element.dataset.partOrderItemId
    row.querySelector(".component_part_number").innerHTML = element.dataset.componentPartNumber
    row.querySelector(".component_infor_number").innerHTML = element.dataset.componentInforNumber
    row.querySelector(".component_name").innerHTML = element.dataset.componentName
    row.querySelector(".component_description").innerHTML = element.dataset.componentDescription
    row.querySelector(".component_image").innerHTML = element.dataset.image
    row.querySelector(".component_id").dataset.value = element.dataset.componentId
    if(document.querySelector("#shipment_weight").dataset.force_manual_weight == false){
      document.querySelector("#shipment_weight").value = Number(document.querySelector("#shipment_weight").value) + (Number(quantity) * element.dataset.weight)
    }
    row.querySelector("input.quantity").value = (Number(row.querySelector("input.quantity").value) + Number(quantity))
    row.querySelector(".quantity_value").innerHTML = Number(row.querySelector("input.quantity").value)
    element.dataset.quantity = Number(element.dataset.quantity) - Number(quantity)
    element.closest('tr').querySelector(".quantity").innerHTML = element.dataset.quantity
    row.querySelector("input.quantity").dispatchEvent(new Event('input'));
  }

  checkHiddenElements(element) {
    const row = element.closest('tr');
    const card = element.closest('.card');
    row.hidden = element.dataset.quantity <= 0;
    row.classList.toggle('good', element.dataset.quantity > 0);
    card.hidden = card.getElementsByClassName('good').length === 0;
  }

  assignQuotedShippingValue() {
    const serviceType = document.querySelector('#shipment_service_type').value;
    const service = serviceType && document.querySelector(`[data-service-type='${serviceType}']`);
    if (service) {
      document.querySelector("#shipment_quoted_shipping_cost").value = service.dataset.price;
    }
  }

  makeVisible(row){
    const field = row.querySelector('input.destroy_field')
    if(field){
      field.value = false
    }
    row.style = null
  }

  connect(){
    console.log("Connected Shipments")
  }

}
