import { Controller, add } from "@hotwired/stimulus"
import TomSelect from "tom-select";

// Connects to data-controller="part-order"
export default class extends Controller {
  static targets = ["shippingAddress", 'billingAddress', "componentField", "component", "hiddenItem", "removeComponent", "addComponent"]

  billing_addresses = new TomSelect("#part_order_billing_address_id",{
      valueField: 'id',
      labelField: 'full_address',
      optgroupField: 'address_type',
      searchField: ['company_name', 'name', 'address_line_1', 'address_line_2', 'city', 'state_province', 'postal_code', 'country'],
      // fetch remote data
      optgroups: [
        {value: 'warehouse', address_type: 'Warehouses'},
        {value: 'retailer', address_type: 'Retailers'},
        {value: 'customer', address_type: 'Customers'},
        {value: 'accounts_payable', address_type: 'Accounts Payables'},
      ],
      load: function(query, callback) {
        let url = document.querySelector("#part_order_billing_address_id").dataset.dataUrl
        if(query){
          url = url + '?query=' + encodeURIComponent(query);
        }
        fetch(url)
        .then(response => response.json())
        .then(json => {
          callback(json);
        }).catch(()=>{
          callback();
        });
      },
      create: function(input, _callback) {
        const newLink = document.querySelector("#add_new_billing_address")
        if(newLink){
          newLink.click()
        }
      },
      render: {
        optgroup_header: function(data, escape) {
          return '<h3 class="text-primary optgroup-header sticky top-0">' + escape(data.address_type) +'</h3>';
        },
        option: function(data, escape) {
          return JSON.parse(data.full_address);
        },
        item: function(data, escape) {
          return JSON.parse(data.full_address);
        }
      }
    });

  shipping_addresses = new TomSelect("#part_order_shipping_address_id",{
    valueField: 'id',
    labelField: 'full_address',
    searchField: ['company_name', 'name', 'address_line_1', 'address_line_2', 'city', 'state_province', 'postal_code', 'country'],
    optgroupField: 'address_type',
    optgroups: [
      {value: 'warehouse', address_type: 'Warehouses'},
      {value: 'retailer', address_type: 'Retailers'},
      {value: 'customer', address_type: 'Customers'},
      {value: 'accounts_payable', address_type: 'Accounts Payables'},
    ],
    // fetch remote data
    load: function(query, callback) {
      let url = document.querySelector("#part_order_shipping_address_id").dataset.dataUrl
      if(query){
        url = url + '?query=' + encodeURIComponent(query);
      }
      fetch(url)
      .then(response => response.json())
      .then(json => {
        callback(json);
      }).catch(()=>{
        callback();
      });
    },
    create: function(input, _callback) {
      const newLink = document.querySelector("#add_new_shipping_address")
      if(newLink){
        newLink.click()
      }
    },
    render: {
      optgroup_header: function(data, escape) {
        return '<h3 class="text-primary optgroup-header sticky top-0">' + escape(data.address_type) +'</h3>';
      },
      option: function(data, escape) {
        return JSON.parse(data.full_address);
      },
      item: function(data, escape) {
        return JSON.parse(data.full_address);
      }
    }
  });

  shipping_service = new TomSelect("#part_order_shipping_service",{
    valueField: 'service_type',
    labelField: 'display',
    optgroupField: 'class',
    optgroups: [
      {value: 'usps', label: 'USPS'},
      {value: 'ups', label: 'UPS'}
    ],
    // fetch remote data
    load: function(query, callback) {
      document.querySelector("#loading-spinner").hidden = false
      const self = this;
      const value = document.querySelector("#part_order_shipping_service").dataset.default
      let shipTo = document.querySelector("#part_order_shipping_address_id").value
      let shipFrom = document.querySelector("#part_order_warehouse_id").value
      let url = new URL(document.querySelector("#part_order_shipping_service").dataset.url, window.location.origin);
      document.querySelectorAll(".component_template").forEach(element => {
        if(element.style.display == 'none'){
        }else{
          for(let i = 0; i < element.querySelector("[data-type='quantity']").value; i++){
            url.searchParams.append('parts[]', element.querySelector("[data-tom-select-target='componentField']").value)
          }
        }
      });
      url.searchParams.append('ship_to', shipTo)
      url.searchParams.append('ship_from', shipFrom)
      console.log("loading rates...")
      fetch(url)
      .then(response => response.json())
      .then(json => {
        callback(json);
        self.setValue(value);
      }).catch(()=>{
        console.log("ERRORS")
        callback();
      });
    },
    onLoad: function(){
      document.querySelector("#loading-spinner").hidden = true
    },
    render: {
      option: function(data, escape) {
        return (data ? JSON.parse(data.display) : "");
      },
      item: function(data, escape) {
        return (data ? JSON.parse(data.display) : "");
      }
    }
  });

  connect(){
    this.shipping_addresses.load()
    this.toggleShippingRates()
  }

  validateAddress(event){
    const value = event.target.value
    const addressTag = event.target.dataset.addressTag
    const object = document.querySelector(addressTag)
    if(object){
      let url = new URL(object.src)
      let params = url.searchParams
      params.set('address_id', value)
      url.search = params.toString()
      object.src = url.toString()
    }
  }

  addComponentTargetConnected(element){
    const id = element.dataset.id
    const inforNumber = element.dataset.inforNumber
    const skuId = element.dataset.skuId
    const existingSelect = document.querySelector(`[data-starting-infor-number='${inforNumber}']`)

    if (existingSelect){
      const input = existingSelect.closest("tr").querySelector("[data-type='quantity']")
      input.closest("tr").style = null
      input.closest("tr").querySelector('input.destroy_field').value = false

      let newNum = parseInt(input.value)
      newNum++
      input.value = newNum
      input.dispatchEvent(new Event('input'));
      input.closest("tr").querySelector("input.sku_field").value = skuId
    }else{
      document.querySelector("#add-component-btn").click()
      const lastRow = document.querySelector("#components-list").querySelector("tr:last-child")
      const select = lastRow.querySelector("select")
      select.dataset.startingValue = id
      select.dataset.startingInforNumber = inforNumber
      lastRow.querySelector("input.sku_field").value = skuId
    }

    element.remove()
  }

  shippingAddressTargetConnected(element){
    const id = element.dataset.id
    const address = JSON.parse(element.dataset.address)
    this.shipping_addresses.removeOption(id)
    this.shipping_addresses.addOption({id: id, full_address: JSON.stringify(address)})
    this.shipping_addresses.setValue(id)
    element.remove()
  }

  removeComponentTargetConnected(element){
    const id = element.dataset.id
    const item = document.querySelector(`[data-value='${id}'].item`)
    console.log(id)
    console.log(item)
    console.log(item.closest("tr"))
    console.log(item.closest("tr").querySelector('.destroy'))
    if(item){
      item.closest("tr").querySelector('.destroy').click()
    }
  }

  checkComponentPredecessor(response){
    if (response && response.predecessor_with_inventory != "null"){
      const predecessor = JSON.parse(response.predecessor_with_inventory)
      const link = document.querySelector("#component_check")
      const url = link.dataset.url + `?component_id=${predecessor.id}&close=true&remove=${response.id}`
      link.href = url
      link.click()
    }
  }

  fetchLocations(event){
    this.locations.clearOptions();
    this.locations.load();
    this.locations.setValue(null)
    this.shipping_addresses.clearOptions();
    this.shipping_addresses.load();
    this.product_skus.clearOptions();
    this.product_skus.load();
    this.product_skus.setValue(null)
  }

  fetchComponentInfo(event){
    const url = event.target.dataset.componentUrl + ".json"
    const tr = event.target.closest('tr')
    const warehouse = document.querySelector("#part_order_warehouse_id")
    let newUrl = url
    if(event.target.value){
      newUrl = url + `?warehouse_id=${warehouse.value}&id=${event.target.value}`
    }

    fetch(newUrl)
      .then(response => response.json())
      .then(json => {
        // for(let key in json.inventory){
        //   console.log(json.inventory[key])
        //   tr.querySelector('#'+key).innerHTML = json.inventory[key]
        // }
        // tr.querySelector('.component_price').innerHTML = `$${json.price}`
        tr.querySelector('.component_price').dataset.value = json.price
        tr.querySelector('.component_unit_cost').dataset.value = json.price
        tr.querySelector('.component_unit_cost').innerHTML = `$${json.price}`
        tr.querySelector('.component_inventory').innerHTML = JSON.parse(json.component_inventory)
        this.updateWarehouseTotals()
        this.calculateTotals()
        this.checkComponentPredecessor(json)
      }).catch(()=>{
        console.log("ERROR")
      });
    }

    getComponentMultiplier(){
      const warehouse = document.querySelector("#part_order_warehouse_id")
      return warehouse.options[warehouse.selectedIndex].dataset.componentPriceMultiplier;
    }

    updateTotal(event){
      // const tr = event.target.closest('tr')
      // const price = tr.querySelector(".component_price")
      // price.innerHTML = `$${(event.target.value * price.dataset.value * this.getComponentMultiplier()).toFixed(2)}`
      this.updateWarehouseTotals()
      this.calculateTotals()
    }

    updateWarehouseTotals(){
      document.querySelectorAll(".component_price").forEach(element => {
        let row = element.closest('tr')
        element.innerHTML = `$${this.getComponentPrice(row)}`
      })
    }

    getComponentPrice(row){
      let component_cost = Math.ceil(row.querySelector(".component_price").dataset.value * this.getComponentMultiplier())
      if(component_cost < 5.0 && component_cost > 0){
        component_cost = 5.0
      }
      return row.querySelector("[data-type='quantity']").value * component_cost
    }

    calculateTotals(){
      let total = 0.0
      const serviceType = this.shipping_service.getValue()
      if(serviceType){
        let service = document.querySelector(`[data-service-type='${serviceType}']`)
        if (service){
          document.querySelector("#part_order_shipping_cost_estimate").value = service.dataset.price
          total += Number(service.dataset.price)
        }
      }
      this.componentTargets.forEach(element => {
        if(element.style.display == 'none'){
        }else{
          total += this.getComponentPrice(element)
        }
      });
      document.querySelector("#grand_total_price").innerHTML = `$${total.toFixed(2)}`
    }

    toggleHiddenItems(){
      this.hiddenItemTargets.forEach(ele => {
        ele.toggleAttribute("hidden")
      });
      document.querySelector("#grand_total_price").classList.toggle('line-through')
      document.querySelector("#grand_total_price").classList.toggle('text-secondary')
    }

    toggleShippingRates(){
      const shippingRates = document.querySelector("#shipping_service_field")
      const warehouse = document.querySelector("#part_order_warehouse_id")
      const option = warehouse.options[warehouse.selectedIndex].dataset.shipStation;
      console.log(option)
      if(option === 'true'){
        shippingRates.hidden = false
      }else{
        shippingRates.hidden = true
      }
    }

    getShippingRates(){
      if(this.shipping_service.getValue()){
        document.querySelector("#part_order_shipping_service").dataset.default = this.shipping_service.getValue()
      }
      const value = document.querySelector("#part_order_shipping_service").dataset.default
      this.shipping_service.setValue(null)
      this.shipping_service.clearOptions();
      this.shipping_service.load()
    }

    getAddressID(addressType, option){
      if(addressType == 'shipping_address'){
        return this.shipping_addresses.getValue()
      }
      if(addressType == 'location'){
        return option.dataset.addressId
      }
    }

    getOptionID(addressType){
      if(addressType == 'shipping_address'){
        return this.shipping_addresses.getValue()
      }
      if(addressType == 'location'){
        return this.locations.getValue()
      }
    }

    getAddressOption(addressType, id){
      if(addressType == 'shipping_address'){
        return this.shipping_addresses.getOption(id)
      }
      if(addressType == 'location'){
        return this.locations.getOption(id)
      }
    }

    billingAddressTargetConnected(element){
      const id = element.dataset.id
      const address = JSON.parse(element.dataset.address)
      this.billing_addresses.removeOption(id)
      this.billing_addresses.addOption({id: id, full_address: JSON.stringify(address)})
      this.billing_addresses.setValue(id)
      element.remove()
    }

}


